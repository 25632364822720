import React,{useEffect} from 'react'

import Layout from "../../components/layout"
//import Seo from "../../components/seo"

const ContactPage = (props) => {

  useEffect(() => {
    //console.log(props);
    document.body.classList.add("contact", "scroll", "regular");

  },[]);

return (
  <Layout bg={'light'}>
  <div className="contentWrapper p-5">
    <h1 className="text-center pb-3">CONTACT</h1>
    <p className="text-center">KLAPP CREATIVE LLC<br/>10753 Yerba Buena Rd.<br/>Malibu CA 90265</p>
    <p className="text-center"><a href="mailto:info@klappcreative.com">info@klappcreative.com</a><br/>
    <a href="tel:+13102201794">Tel: +1 3102201794</a>
    </p>
    </div>
  </Layout>
)
}
export default ContactPage

export const Head = () => (
  <>
    <title>Frank Ariza - AF Films - Contact</title>
    <meta name="description" content="AF Films - Contact" />
  </>
)
